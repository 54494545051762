import { FunctionComponent, ReactElement, VoidFunctionComponent, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { SwiperProps, SwiperSlideProps } from 'swiper/react';

import { useMobile } from '@/lib/hooks/useMobile';
import { CarouselFragment } from './__generated__/CarouselFragment';

import { useTheme } from "styled-components";
import { SwiperModule } from 'swiper/types';
import GenericCard from '../GenericCard';
import {
  StyledArrows,
  StyledCarousel,
  StyledOverviewAnchor,
  StyledOverviewAnchorWrapper,
  StyledPagination,
  StyledTopWrapper
} from './Carousel.styles';
import { getLanguage } from '@/lib/config';

//Component
export default function Carousel(props: CarouselFragment): ReactElement {
  const isMobile = useMobile();
  const { key } = useTheme();
  const language = getLanguage();

  // Dynamically load swiper.
  const [swiperModules, setSwiperModules] = useState<SwiperModule[]>([]);
  const [Swiper, setSwiper] = useState<FunctionComponent<SwiperProps> | null>(null);
  const [SwiperSlide, setSwiperSlide] = useState<VoidFunctionComponent<SwiperSlideProps> | null>(null);

  useEffect(() => {
    const getSwiperModules = async () => {
      const modules = await import('swiper');
      const { Pagination, Navigation } = modules;
      const swiperComponents = await import('swiper/react');

      setSwiperModules([Pagination, Navigation]);
      setSwiper(swiperComponents.Swiper);
      setSwiperSlide(swiperComponents.SwiperSlide);
    };

    getSwiperModules();
  }, []);
  // Dynamically load swiper - End.

  if (!props?.cards?.length || props?.cards?.length < 3) {
    return <></>;
  }
  if (swiperModules.length > 0 && Swiper && SwiperSlide) {
    return (
      <StyledCarousel>
        <StyledTopWrapper>
          {props.carouselTitle && <h2 lang={language !== 'en' ? language : undefined}>{props.carouselTitle}</h2>}
          <StyledArrows>
            <button className={`carousel__prev carousel__prev-${props.id}`} aria-label="Previous carousel slide">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <circle r="24" transform="matrix(-1 0 0 1 24 24)" fill="#008945"/>
                  <path d="M33.8823 22.5H35.3823V25.5H33.8823V22.5ZM12.4307 25.0607C11.8449 24.4749 11.8449 23.5251 12.4307 22.9393L21.9767 13.3934C22.5625 12.8076 23.5122 12.8076 24.098 13.3934C24.6838 13.9792 24.6838 14.9289 24.098 15.5147L15.6127 24L24.098 32.4853C24.6838 33.0711 24.6838 34.0208 24.098 34.6066C23.5122 35.1924 22.5625 35.1924 21.9767 34.6066L12.4307 25.0607ZM33.8823 25.5L13.4914 25.5V22.5L33.8823 22.5V25.5Z" fill="#C7F700"/>
                </g>
              </svg>
            </button>
            <button className={`carousel__next carousel__next-${props.id}`} aria-label="Next carousel slide">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="24" fill="#008945"/>
                <path d="M14.1177 22.5H12.6177V25.5H14.1177V22.5ZM35.5693 25.0607C36.1551 24.4749 36.1551 23.5251 35.5693 22.9393L26.0233 13.3934C25.4375 12.8076 24.4878 12.8076 23.902 13.3934C23.3162 13.9792 23.3162 14.9289 23.902 15.5147L32.3873 24L23.902 32.4853C23.3162 33.0711 23.3162 34.0208 23.902 34.6066C24.4878 35.1924 25.4375 35.1924 26.0233 34.6066L35.5693 25.0607ZM14.1177 25.5L34.5086 25.5V22.5L14.1177 22.5V25.5Z" fill="#C7F700"/>
              </svg>
              {/*<img src="/Carousel/car-arrow-right.svg" alt="Next slide" />*/}
            </button>
          </StyledArrows>
        </StyledTopWrapper>
        <Swiper
          navigation={{ nextEl: `.carousel__next-${props.id}`, prevEl: `.carousel__prev-${props.id}` }}
          spaceBetween={16}
          slidesPerView="auto"
          pagination={{ clickable: true, el: `.carousel__pagination-${props.id}` }}
          modules={swiperModules}
          breakpoints={{
            1250: {
              spaceBetween: 32,
            },
          }}
          wrapperTag="ul"
        >
          {props.cards.map((card) => (
            <SwiperSlide key={card.id} tag="li">
              <GenericCard
                path={card.path}
                title={card.title}
                description={card.teaserDescription ?? ''}
                eyebrow={card.teaserEyebrow ?? undefined}
                image={{ ...card.teaserImage!, alt: card.teaserImage?.altText ?? 'Card image' }}
                h3
                hideDescription={!props.showDescription}
                hideEyebrow={!props.showEyebrow}
              />
            </SwiperSlide>
          ))}
          {props?.overviewLink?.url && props?.overviewLink?.title && (
            <SwiperSlide tag="li">
              <StyledOverviewAnchorWrapper>
                <StyledOverviewAnchor
                  onClick={(e) => {
                    if (props?.overviewLink?.url) {
                      window.location.href = props.overviewLink.url
                    }
                  }}
                >
                  <span>{props?.overviewLink?.title}</span>
                  {isMobile ? (
                    <svg
                      className="arrow-svg"
                      width="31"
                      height="16"
                      viewBox="0 0 31 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 7H0L0 9H1L1 7ZM30.5943 8.70711C30.9848 8.31658 30.9848 7.68342 30.5943 7.29289L24.2303 0.928932C23.8398 0.538408 23.2066 0.538408 22.8161 0.928932C22.4256 1.31946 22.4256 1.95262 22.8161 2.34315L28.4729 8L22.8161 13.6569C22.4256 14.0474 22.4256 14.6805 22.8161 15.0711C23.2066 15.4616 23.8398 15.4616 24.2303 15.0711L30.5943 8.70711ZM1 9L29.8872 9V7L1 7L1 9Z"
                        fill="#FFD200"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="arrow-svg"
                      width="33"
                      height="24"
                      viewBox="0 0 33 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 10.5156H0.5L0.5 13.5156H2L2 10.5156ZM31.9478 13.0763C32.5336 12.4905 32.5336 11.5408 31.9478 10.955L22.4019 1.40902C21.8161 0.823236 20.8663 0.823236 20.2806 1.40902C19.6948 1.99481 19.6948 2.94456 20.2806 3.53034L28.7658 12.0156L20.2806 20.5009C19.6948 21.0867 19.6948 22.0364 20.2806 22.6222C20.8663 23.208 21.8161 23.208 22.4019 22.6222L31.9478 13.0763ZM2 13.5156L30.8872 13.5156V10.5156L2 10.5156L2 13.5156Z"
                        fill="#FFD200"
                      />
                    </svg>
                  )}
                  {key !== 'zsl' && (
                    <img loading="lazy" alt="" className="pattern-texture-e" src="/PatternTextureE.svg" />
                  )}
                </StyledOverviewAnchor>
              </StyledOverviewAnchorWrapper>
            </SwiperSlide>
          )}
          <StyledPagination className={`carousel__pagination carousel__pagination-${props.id}`} />
        </Swiper>
      </StyledCarousel>
    )
  }
  else {
    return <>Loading...</>
  }
}
